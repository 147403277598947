<template>
  <div>
    <section class="lg py-5">
      <v-container grid-list-xs>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 sm12 md6 lg6 pa-2>
            <h1 class="display-1 b primaryDark--text">Upcoming Event</h1>
            <h1 class="title">Ethiopian New Year</h1>
            <p class="body-1 py-2">
              Tesfa Coffee has hosted a special event to celebrate Ethiopian New
              Year with a hottet vibes. Everyone is invited to attend the event
              on September 1, 2014 Ethiopian Calendar.
            </p>
            <p class="body-1">
              Address:
              <a
                class="link primary--text mr-4"
                href="https://www.google.com/maps/place/Tesfa+Coffee+%7C+%E1%89%B0%E1%88%B5%E1%8D%8B+%E1%89%A1%E1%8A%93/@9.0384938,38.7628791,15z/data=!4m5!3m4!1s0x0:0xa54ca2c0b5fabe73!8m2!3d9.0384908!4d38.7628799"
                target="_blank"
                ><v-icon color="primary">mdi-google-maps</v-icon> Tesfa Coffee(
                5 Kilo, Addis Ababa,Ethiopia)</a
              >
            </p>
            <!-- <div>
              <v-btn color="primaryDark" dark class="text-capitalize"
                >Register Now</v-btn
              >
            </div> -->
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 pa-2>
            <v-card height="100%" flat>
              <v-img
                src="https://brilliant-ethiopia.imgix.net/ethiopian-new-year-2.jpg?auto=format,enhance,compress&fit=crop&crop=faces,edges&w=0&h=288"
                height="100%"
                width="100%"
                max-height="300px"
              ></v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <section>
      <v-container grid-list-xs>
        <h1 class="display-1 b text-center primaryDark--text">Past Events</h1>
        <p class="body-1 text-center">
          Tesfa Coffee is known for its hottest Teatrical events hosting in
          addition to its delicious coffee ceremony.
        </p>
        <v-layout row wrap>
          <v-flex
            xs12
            sm12
            md4
            lg4
            pa-2
            v-for="item in events"
            :key="item.tilte"
            style="cursor: pointer"
          >
            <v-img
              :src="item.img"
              width="100%"
              height="100%"
              max-height="200px"
            ></v-img>
            <div class="my-2">
              <h1 class="title primaryDark--text">{{ item.title }}</h1>
              <p class="body-2">{{ item.desc }}</p>
            </div>
          </v-flex>
        </v-layout>
        <div class="text-center">
          <router-link class="link title b" to="">See More </router-link>
        </div>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: [
        {
          img: require("../../assets/gallery/g1.jpeg"),
          title: "Coffee Ceremony",
          desc: `Students, Teachers, and other communities from Addis Ababa university were enjoying Tesfa Coffee.`,
        },
        {
          img: require("../../assets/gallery/g2.jpg"),
          title: "Guests",
          desc: `Guests coming from outside Ethiopia prefers Tesfa Coffee as their first choice.`,
        },
        {
          img: require("../../assets/C10.jpg"),
          title: "Teatrical Arts",
          desc: `There was a Teatrical Arts events every Saturday in collaboration with the renowned 
          Ethiopian Artists.`,
        },
      ],
    };
  },
};
</script>